/*=========================================================================================
  File Name: moduleDoctorActions.js
  Description: Doctor Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddDoctor({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/AddDoctor", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.Data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    AddUserDoctor({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("Registration", item)
                .then(response => {
                    debugger;
                    commit("ADD_ITEM", Object.assign(item, { ID: response.data.Data }));
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },

    GetAllDoctors({ commit }, search) {
        debugger
        //search2menuDoctor
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/SearchDoctors", search)
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetDoctorsBySpeciality({ commit }, SpecialtyID) {
        debugger
        //search2menuDoctor
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/GetDoctorsBySpeciality?SpecialtyID=" + SpecialtyID)
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllDoctorsDdl({ commit }, search) {
        debugger
        //search2menuDoctor
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/SearchDoctors", search)
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SearchDoctors({ commit }, search) {
        debugger
        //getListOfDoctor
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/SearchDoctors", search)
                .then(response => {
                    commit("SET_SearchDoctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SimpleSearchDoctors({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/SimpleSearchDoctors", search)
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    getDoctorClendarYearSummary(context, search) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/DoctorSession/getDoctorClendarYearSummary?doctorID=" + search.DoctorID + "&year=" + search.Year)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchDoctorsPaging({ commit }, search) {
        //search1
        //getListOfDoctor
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/NewSearchDoctors", search)
                //  .post("api/Doctor/SearchDoctors", search)
                .then(response => {
                    commit("SET_SearchDoctorPaging", response.data.Data);
                    resolve(response);
                    debugger
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchDoctorMonthSessions(context, search) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/DoctorSession/SearchDoctorMonthSessions", search)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchDoctorSessions(context, search) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/DoctorSession/SearchDoctorSessions", search)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SimpleSearchDoctorSessions(context, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/DoctorSession/SimpleSearchDoctorSessions", search)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    AddPatientReservationSessionSymptomNote(context, model) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "api/PatientReservationSession/AddPatientReservationSessionSymptomNote?sessionID=" +
                    model.ID +
                    "&note=" +
                    model.SymptomNote
                )
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientReservationSession(context, model) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "api/PatientReservationSession/AddPatientReservationSession",
                    model
                )
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddDoctorUserReview(context, model) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/DoctorUserReview/AddDoctorUserReview", model)
                .then(response => {
                    resolve(response);

                    window.showSuccess(res.data.message);
                })
                .catch(error => {
                    reject(error);
                    window.showError(err.response.data.message);
                });
        });
    },
    SaveDoctorSession(context, model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("API/DoctorSession/SaveDoctorSessions", model)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    BlockAvailability(context, model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("API/DoctorSession/BlockDoctorSessions", model)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    EditAvailability(context, model) {
        return new Promise((resolve, reject) => {
            axios
                .post("API/DoctorSession/EditDoctorSessions", model)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    PreviewOnCalendar(context, model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("API/DoctorSession/AddDoctorSessions", model)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    PayReservedSession(context, reservedSessionId) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "API/PatientReservationSession/PayReservedSession?reservedSessionId=" +
                    reservedSessionId
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdateDoctor(context, item) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .post("api/Doctor/UpdateDoctor", item)
                .then(response => {
                    // commit('UPDATE_Doctor', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SaveDoctorSessionPrice(context, item) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .post("api/DoctorSessionPrice/UpdateDoctorSessionPrice", item)
                .then(response => {
                    // commit('UPDATE_Doctor', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetDoctorSessionPriceByDoctorID(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get(
                    "api/DoctorSessionPrice/GetDoctorSessionPriceByDoctorID?DoctorID=" +
                    itemid
                )
                .then(response => {
                    // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetDoctor(context, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("api/Doctor/GetDoctorByID?doctorID=" + itemid)
                .then(response => {
                    // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                    debugger;
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationSession(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get(
                    "api/PatientReservationSession/GetPatientReservationSession?patientReservationID=" +
                    itemid
                )
                .then(response => {
                    //                 // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationSessionAndSendNotification(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get(
                    "api/PatientReservationSession/GetPatientReservationSessionAndSendNotification?patientReservationID=" +
                    itemid
                )
                .then(response => {
                    //                 // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationSessionViaDoctor(context, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get(
                    "api/PatientReservationSession/GetPatientReservationSessionViaDoctor?patientReservationID=" +
                    itemid.sessionID + "&IsDoctorRequest=" + itemid.IsDoctorRequest
                )
                .then(response => {
                    // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    AddMedicalPrescription(context, medicalModel) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/PatientReservationSessionPrescription/AddMedicalPrescription", medicalModel)
                .then((response) => {




                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteMedicalPrescription(context, medicalModel) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/PatientReservationSessionPrescription/DeleteMedicalPrescription?MedicalPrescriptionId=" + medicalModel.ID)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteDoctor({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/DeleteDoctor?doctorID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeEducation(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/DeleteEducationByID?doctorEducationID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeExperience(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "api/Doctor/DeleteExperienceByDoctorID?doctorExperienceID=" + item.ID
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeLink(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/DeleteLinkByDoctorID?doctorLinkID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeSessionPrice(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/DeleteSessionPriceByDoctorID?doctorLinkID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //get your patient mkl
    getPatientReservationCalender({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/SearchPatientReservationSessions",
                    item
                )
                .then(response => {
                    debugger;

                    commit("GET_PATIENT_RESERVATION_Month", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },
    getPatientReservation({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/SearchPatientReservationSessions",
                    item
                )
                .then(response => {
                    debugger;

                    commit("GET_PATIENT_RESERVATION", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },
    GetDoctorPatients({ commit }, doctorId) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Doctor/GetDoctorPatients?doctorId=" + doctorId)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },
    CompletePatientReservation({ commit }, ID) {
        var session = {}
        session.PatientReservationSessionID = ID;
        session.StatusID = 5;
        debugger;
        return new Promise((resolve, reject) => {
            axios.post("API/PatientReservationSession/ChangePatientReservationSessionStatus", session)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
        // return new Promise((resolve, reject) => {
        //     axios
        //         .post(
        //             "/API/PatientReservationSession/CompletePatientReservation?ID=" + ID
        //         )
        //         .then(response => {
        //             debugger;

        //             commit("GET_PATIENT_RESERVATION", response.data.Data);
        //             resolve(response);
        //         })
        //         .catch(error => {
        //             debugger;
        //             reject(error);
        //         });
        // });
    },
    //get your patient download report mkl
    PatientMedicalReportRequestDownloadDoc({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/api/Patient/GetAllPatientMedicalReportReservationDownload",
                    item
                )
                .then(response => {
                    debugger;

                    commit("GET_PATIENT_RESERVATION", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },

    //change status of download document
    ChangeDocStatus({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/api/Doctor/ChangeDocStatus",
                    item
                )
                .then(response => {
                    debugger;

                    //commit("GET_PATIENT_RESERVATION", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },
    //change download Detials
    changeDowloadDocumentStatus({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/api/Patient/GetAllPatientMedicalReportReservationDownload",
                    item
                )
                .then(response => {
                    debugger;

                    commit("GET_PATIENT_RESERVATION", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },
    GetAllPatientMedicalReportDownloadHospitalSurgery({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/api/Patient/GetAllPatientMedicalReportDownloadHospitalSurgery",
                    item
                )
                .then(response => {
                    debugger;

                    commit("GET_PATIENT_RESERVATION", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    },

    //get pending doctor task mkl
    GetPendingDoctorTaskList({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Doctor/GetPendingDoctorTask?ID=" + item)
                .then(response => {
                    debugger;

                    commit("GET_PENDING_TASK_LIST", response.data.Data);
                    debugger;
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    reject(error);
                });
        });
    }
};
