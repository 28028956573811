/*=========================================================================================
  File Name: moduleDoctorMutations.js
  Description: Doctor Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.doctors.unshift(item)
    },
    SET_Doctor(state, doctors) {
        state.doctors = doctors
    },
    SET_SearchDoctor(state, doctors) {
        state.searchDoctors = doctors

    },
    SET_SearchDoctorPaging(state, doctors) {
        debugger
        if(doctors!=null&&doctors.length>0){
            doctors.forEach(element => {
                const ItemIndex = state.searchDoctors.findIndex(p => p.ID == element.ID);
                if(ItemIndex==-1 )
                  state.searchDoctors.push(element)
            });
        }
       
    },
    SET_specialities(state, specialities) {
        state.specialities = specialities
    },
    SET_DoctorLanguages(state, Langauges) {
        state.Langauges = Langauges
    },
    UPDATE_Doctor(state, Doctor) {
        const DoctorIndex = state.doctors.findIndex((p) => p.ID == Doctor.ID)
        Object.assign(state.doctors[DoctorIndex], Doctor)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.doctors.findIndex((p) => p.ID == itemId)
        state.doctors.splice(ItemIndex, 1)
    },
    GET_PATIENT_RESERVATION(state, patientReservation) {
        debugger
        state.patientReservation = patientReservation
        debugger
    },
    GET_PATIENT_RESERVATION_Month(state, patientReservation) {

        state.monthPatientReservation = patientReservation

    },
    GET_PENDING_TASK_LIST(state, pendingTaskList) {
        debugger
        state.pendingTaskList = pendingTaskList
        debugger
    },
}