<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >
    <!-- Left Card -->
    <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <h4>{{ $t("ThreeSetAppointment") }}</h4>
          <h5
            class="pl-10 pr-10"
            style="
              text-align: center;

              opacity: 1;
            "
          >
            {{ $t("ProccedOnlineAppointment") }}
            <br />
            {{ $t("withDoctor") + " "
            }}<span style="color: #2753D9">{{ sessionModel.DoctorName }}</span>
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Full Name -->
            <b-col cols="12" md="6">
              <imageLazy
                :imageStyle="{
                  'border-radius': '20px',
                  width: '20vh',
                  height: '20vh',
                }"
                :imageClass="'doctorImage'"
                :src="baseURL + sessionModel.doctorImg"
                placeHolderType="doctor"
              />
            </b-col>
            <b-col cols="12" md="6">
              <b-row class="text-start mt-1">
                <label for="fname">{{ $t("Date") }} :</label>
                <p>
                  {{ changeFormatedDate(sessionModel.SessionDate) }}
                </p>
              </b-row>
              <b-row>
                <label>{{ $t("Time") }} :</label>
                <p
                  class="text-start"
                  v-if="
                    sessionModel.TimeFrom != null && sessionModel.TimeTo != null
                  "
                >
                  {{ sessionModel.TimeFrom.slice(0, 5) }} -
                  {{ sessionModel.TimeTo.slice(0, 5) }}
                </p>
              </b-row>
              <b-row>
                <label>{{ $t("Doctor") }} :</label>
                <p>{{ sessionModel.DoctorName }}</p>
              </b-row>
              <b-row>
                <label>{{ $t("Destination") }} :</label>
                <p>
                  {{ sessionModel.Country }}
                </p>
              </b-row>
              <b-row>
                <label>{{ $t("Speciality") }} :</label>
                <p>{{ sessionModel.Specialties }}</p>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

    <!-- Right Card -->
    <div class="customer-card">
        <b-card :title="$t('payOrPaylaterNote1')">
          <b-card-text class="mb-0">
            <p style="color: orange"></p>
            <ul class="m-2">
              <li style="color: orange">
                {{ $t("payOrPaylaterNote2") }}
              </li>
              <li style="color: orange">
                {{ $t("payOrPaylaterNote3") }}
              </li>
             
            </ul>
          </b-card-text>
          <!-- Submit Button -->
          <b-button
            variant="primary"
            block
            @click="PayNow"
                    >{{ $t("PayNow") }}
          </b-button>
          <hr>
          <b-button
          v-if="sessionModel.Paylater && (changeFormatedDate(sessionModel.SessionDate) != changeFormatedDate(appointmentDate))"
            block
            @click="$router.push({
          name: 'BookingSummary',
          params: { Flag: 1, ID: updatedPatientSession.ID },
        })"
          >
            <u>{{$t('PayLater')}}</u>
          </b-button>
         
          <p v-else style="color:red">{{$t('CantPaylater')}}</p>

        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BRow } from 'bootstrap-vue';
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      baseURL: domain,
      isSelected: false,
      appointmentDate: new Date().toDateString(),
      appointmentDateStrig: "",
      sessions: [],

    };
  },
  components: {
    // BSV
    ValidationObserver, ValidationProvider,
    imageLazy,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,BCol,BRow,
  },
  props: {
    sessionModel: {
      type: Object,
      required: true,
    },
    updatedPatientSession: {
      type: Object,
      default: () => {},
    },
  
  },
  
  methods:{
    changeFormatedDate(date) {
      return moment(date).format("LL");
    },
    PayNow() {
      debugger
    this.updatedPatientSession.FrontUrl = window.location.origin
      this.$store
        .dispatch("patientList/AppointmentReservationPaymentDatail", this.updatedPatientSession)
        .then((res) => {
          debugger;
          window.location.href  = res.data.Data.transaction.url;          
        });

    },

  }
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
