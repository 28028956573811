var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-view product-checkout mt-0"},[_c('doctor-list',{attrs:{"searchDoctors":_vm.searchDoctors},on:{"viewAppointment":_vm.viewAppointment}}),_c('div',{},[_c('b-col',{staticClass:"checkout-options sticky"},[_c('b-card',[(_vm.showAppointments&&_vm.doctor)?_c('h6',{staticClass:"mb-0 item-name",staticStyle:{"color":"#8379F2","font-weight":"bold"}},[( _vm.doctor.CompleteFullName)?_c('b-link',[_c('span',{staticClass:"company-name"},[_vm._v(_vm._s(_vm.$t("Dr"))+"."+_vm._s(_vm.doctor.CompleteFullName))])]):_c('b-link',[_c('span',{staticClass:"company-name"},[_vm._v(" "+_vm._s(_vm.$t("Dr"))+"."+_vm._s(_vm.doctor.Name)+" ")])])],1):_vm._e(),_c('label',{staticClass:"section-label mb-1"},[_vm._v("Options")]),(_vm.showAppointments&&_vm.doctor&&_vm.doctor.HaseAppointemts)?_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"locale":_vm.$vs.rtl ? 'ar' : 'en',"attributes":[ {
        bar: 'green',
        dates: this.monthSessions.map(function (ob) { return ob.DateFrom; }),
      } ],"min-date":_vm.minDate},on:{"update:from-page":_vm.pageChange,"input":_vm.getAppointmentSessions},model:{value:(_vm.appointmentDate),callback:function ($$v) {_vm.appointmentDate=$$v},expression:"appointmentDate"}}):_vm._e(),(!_vm.showAppointments)?_c('h1',{staticClass:"price-title"},[_vm._v(" "+_vm._s(_vm.$t("PleaseSelectOnADoctor"))+" ")]):_vm._e(),(_vm.showAppointments&&_vm.doctor&&_vm.doctor.HaseAppointemts)?_c('hr'):_vm._e(),_c('div',{staticClass:"price-details"},[(_vm.showAppointments&&_vm.doctor&&_vm.doctor.HaseAppointemts)?_c('h6',{staticClass:"price-title"},[_vm._v(" "+_vm._s(_vm.$t("SelectATimeFor"))+" "+_vm._s(_vm.appointmentDateStrig)+" : ")]):_vm._e(),(_vm.showAppointments)?_c('b-row',_vm._l((_vm.sessions),function(item){return _c('b-row',{key:item.name,staticClass:"p-0 m-0"},[_c('div',{on:{"click":function($event){return _vm.selectSession(item)}}},[_c('div',{staticClass:"m-1",style:([
                    !item.IsAvailable
                      ? { 'background-color': 'gray' }
                      : item.ID == _vm.Session.ID
                      ? {
                          'background-color': '#004477',
                          'font-color': 'white',
                        }
                      : item.IsBlocked
                      ? { 'background-color': 'red', 'font-color': 'white' }
                      : {'background-color': 'green', 'font-color': 'white'} ])},[(item.DateFrom != null && item.DateTo != null)?_c('p',{staticClass:"text-center m-1",style:([
                      item.ID == _vm.Session.ID
                        ? { color: 'white' }
                        : item.IsBlocked
                        ? { color: 'white' }
                        : !item.IsAvailable
                        ? { color: 'white' }
                        : {color: 'white'} ])},[_vm._v(" "+_vm._s(item.DateFrom.slice(11, 16))+" - "+_vm._s(item.DateTo.slice(11, 16))+" ")]):_vm._e()])])])}),1):_vm._e(),_c('hr'),(
              (_vm.sessions.length > 0 &&
                !(
                  _vm.sessions.findIndex(
                    function (element) { return element.IsAvailable == true; }
                  ) >= 0
                )) ||
              (_vm.doctor.HaseAppointemts != true && _vm.showAppointments)
            )?_c('p',{staticClass:"m-2 ml-5",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("onlineAppointmentSpecificDateMessage"))+" "),_c('u',{staticStyle:{"color":"blue"}},[_c('a',{attrs:{"href":'mailto:patient.service@doclinia.com' +
                  '?subject=' +
                  'Request an online consultation with Dr.' +
                  _vm.doctor.Name +
                  ',Date:' +
                  _vm.changeFormatedDate(this.appointmentDate)}},[_vm._v("patient.service@doclinia.com")])])]):_vm._e(),(_vm.Session.ID > 0 && _vm.showAppointments)?_c('b-row',[_c('b-col',[_c('div',{staticClass:"mt-8 mb-5 mr-8 flex flex-wrap items-center justify-end"},[_c('b-button',{attrs:{"disabled":!_vm.Session.ID,"variant":"primary","block":""},on:{"click":function($event){return _vm.$emit('next-step',_vm.Session,_vm.doctor)}}},[_vm._v(" "+_vm._s(_vm.$t("Procced"))+" ")])],1)])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }