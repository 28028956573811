/*=========================================================================================
  File Name: moduleCityActions.js
  Description: City Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddCity({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/City/AddCity", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllCitiesByCountryID({ commit }, CountryID) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/City/GetAllCitiesByCountryID?CountryID=" +
          CountryID
        )
        .then(response => {
          commit("SET_City", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  
  GetAllCitiesByRegionID({ commit }, RegionID) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/City/GetAllCitiesByRegionID?RigonID=" +
          RegionID
        )
        .then(response => {
          commit("SET_City", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetAllCities({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/City/GetAllCities")
        .then((response) => {
          debugger
          commit('SET_City', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateCity({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/City/UpdateCity", item)
        .then((response) => {
          commit('UPDATE_City', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteCity({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/City/DeleteCity?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetCity({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/City/GetCity", itemid)
        .then((response) => {
          commit('UPDATE_City', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
