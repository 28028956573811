<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <h4>{{ $t("OneSetAppointment") }}</h4>
          <h5
            class="pl-10 pr-10"
            style="
              text-align: center;

              opacity: 1;
            "
          >
            {{ $t("ProccedOnlineAppointment") }}
            <br />
            {{ $t("withDoctor") + " "
            }}<span style="color: #2753D9">{{ sessionModel.DoctorName }}</span>
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Full Name -->
            <b-col cols="12" md="6">
              <div class="mt-2 w-full">
                <p style="color: black">
                  {{ $t("AppointmentPatientNotePlease") }}
                </p>

                <b-form-textarea
                  style="color: black"
                  class="text-1xl w-full"
                  :placeholder="$t('PleaseTypeHere')"
                  v-model="patientNote"
                >
                </b-form-textarea>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <!-- start -->

              <div>
                <b-col>
                  <span>{{ $t("selectAlreadyAddDoc") }}</span>
                  <b-row>
                    <b-col cols="12" md="9">
                      <v-select
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        class="mb-5"
                        label="Description"
                        v-model="documentUploadedModel"
                        :options="ProfileDocument"
                        :reduce="(ID) => ID"
                        :placeholder="$t('UploadedDocument')"
                      />
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click="AddFromProfileReport()"
                        :disabled="!documentUploadedModel"
                      >
                        {{ $t("Add") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col>
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    class="mb-5"
                    label="Name"
                    v-model="documentModel.DocumentID"
                    :options="DocumentTypes"
                    :reduce="(ID) => ID.ID"
                    :placeholder="$t('DocumentType')"
                  />
                </b-col>

                <b-col v-if="documentModel.DocumentID == 1">
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    label="Name"
                    v-model="documentModel.DocumentTypeID"
                    :options="ScanTypes"
                    :reduce="(ID) => ID.ID"
                    :placeholder="$t('ScanType')"
                  />
                </b-col>
                <b-col v-if="documentModel.DocumentID == 2">
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    label="Name"
                    v-model="documentModel.DocumentTypeID"
                    :options="AnalysisTypes"
                    :reduce="(ID) => ID.ID"
                    :placeholder="$t('AnalysisType')"
                  />
                </b-col>
                <b-col v-if="documentModel.DocumentID == 3">
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    label="Name"
                    v-model="documentModel.DocumentTypeID"
                    :options="ReportTypes"
                    :reduce="(ID) => ID.ID"
                    :placeholder="$t('ReportType')"
                  />
                </b-col>
                <b-col>
                  <b-form-textarea
                    class="mt-3"
                    v-model="documentModel.Description"
                    :placeholder="$t('Description')"
                  >
                  </b-form-textarea>
                </b-col>
                <b-col v-if="documentModel.DocumentID > 0">
                  <div>
                    <input
                      :disabled="
                        !(
                          documentModel.DocumentID > 0 &&
                          documentModel.DocumentTypeID > 0 &&
                          documentModel.Description
                        )
                      "
                      type="file"
                      class=""
                      accept=".pdf"
                      @change="AttachmentUploaded"
                      @on-success="successUpload"
                    />
                  </div>
                </b-col>
              </div>

              <b-row class="PatrientNote">
                <table
                  style="width: 100%; background-color: white"
                  class="mt-5"
                >
                  <tr class="HeaderTableColor fontheader" style="height: 50px">
                    <th class="text-center">
                      {{ $t("Description") }}
                    </th>

                    <th class="text-center">
                      {{ $t("DocumentType") }}
                    </th>
                    <th class="text-center"></th>
                  </tr>
                  <tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in ReportDocuments"
                    style="height: 20px"
                  >
                    <td class="text-center">
                      <p class="product-name font-medium truncate">
                        {{ tr.Description }}
                      </p>
                    </td>
                    <td class="text-center">
                      <p class="product-name font-medium truncate">
                        {{ tr.Name }}
                      </p>
                    </td>

                    <td style="width: 10px">
                      <div class="">
                        <feather-icon
                          icon="TrashIcon"
                          @click.stop="RemoveDocuments(indextr)"
                          class="cursor-pointer mt-2"
                        ></feather-icon>
                      </div>
                    </td>
                  </tr>

                  <!-- <tr style="height: 50px"></tr> -->
                </table>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <div class="customer-card">
        <b-card :title="$t('Options')">
          <b-button variant="primary" block @click="SaveModel">{{
            $t("Save")
          }}</b-button>
        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import {
BButton,
BCard,
BCardBody,
BCardHeader,
BCardText,
BCardTitle,
BCol,
BForm,
BFormGroup,
BFormInput,
BFormInvalidFeedback,
BFormTextarea,
BRow
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    // BSV
    BFormTextarea,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    updatedPatientSession: {
      type: Object,
      default: () => {},
    },
    sessionModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSelected: false,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
      ReportDocuments: [],
      ProfileReportDocuments: [],
      ProfileReportDocumentID: "",
      documentUploadedModel: null,
      uploaded: false,
      patientNote: "",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      documentModel: {},
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    DocumentTypes() {
      debugger;
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ProfileDocument() {
      debugger;
      return this.ProfileReportDocuments;
    },
    ScanTypes() {
      debugger;
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      debugger;
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      debugger;
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    SaveModel() {
      debugger;
      
       this.AddListOfAttachment();
    },
    AddListOfAttachment() {
      this.$store
        .dispatch(
          "patientList/AddListPatientMedicalDocument",
          this.ReportDocuments
        )
        .then(() => {
          this.SavePatientNote();
        });
    },

    AddFromProfileReport() {
      debugger;
      if (
        this.documentUploadedModel.DocumentID != null &&
        this.documentUploadedModel.DocumentID > 0
      ) {
        this.documentModel = {};
        this.documentModel.Description =
          this.documentUploadedModel.Description +
          "-" +
          "Session-" +
          this.updatedPatientSession.ID;
        if (this.documentUploadedModel.DocumentID == 1)
          this.documentModel.Name = "Scan Type";
        if (this.documentUploadedModel.DocumentID == 2)
          this.documentModel.Name = "Analysis Type";
        if (this.documentUploadedModel.DocumentID == 3)
          this.documentModel.Name = "Report Type";
        this.documentModel.DocumentID = this.documentUploadedModel.DocumentID;
        this.documentModel.DocumentTypeID =
          this.documentUploadedModel.DocumentTypeID;
        this.documentModel.ReportTypeID =
          this.documentUploadedModel.ReportTypeID;
        this.documentModel.ScanTypeID = this.documentUploadedModel.ScanTypeID;
        this.documentModel.AnalysisTypeID =
          this.documentUploadedModel.AnalysisTypeID;
        this.documentModel.AttachPath = this.documentUploadedModel.AttachPath;
        this.documentModel.PatientID =
          this.$store.state.AppActiveUser.Patient.ID;
        this.ShowPatientUploadDocument = true;
        this.documentModel.PatientReservationSessionID =
          this.updatedPatientSession.ID;
        debugger;
        this.documentModel.DoctorID = this.updatedPatientSession.DoctorID;
        this.documentModel.IsFromSession = true;
        this.ReportDocuments.push(this.documentModel);
        this.documentUploadedModel = {};
        this.documentModel = {};
      }

      debugger;
    },

    SavePatientNote() {
      debugger;
      var model = {};
      model.ID = this.updatedPatientSession.ID;
      model.SymptomNote = this.patientNote;

      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/AddPatientReservationSessionSymptomNote", model)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.$vs.loading.close();
            this.updatedPatientSession = res.data.Data;
            this.ShowPatientNote = false;
            window.showAddSuccess();
            var ID = this.updatedPatientSession.ID;
            this.$emit('next-step',this.sessionModel,this.updatedPatientSession)

            //this.$router.push("/Patient/Home");
            debugger;
            //, params: {Flag:True ,SessionID:this.updatedPatientSession.ID}
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    RemoveDocuments(index) {
      this.ReportDocuments.splice(index, 1);
    },
    AddAttachmnet() {
      debugger;
      this.documentModel.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.ShowPatientUploadDocument = true;
      this.documentModel.PatientReservationSessionID =
        this.updatedPatientSession.ID;
      debugger;
      this.documentModel.DoctorID = this.updatedPatientSession.DoctorID;
      this.documentModel.IsFromSession = true;
      debugger;
      this.$store
        .dispatch("patientList/AddPatientMedicalDocument", this.documentModel)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            if (this.documentModel.IsFromSession != true) {
              // this.getModel();
              this.$emit("openMedicalDocument");
            }
            debugger;
            //document.getElementsByClassName("btn-x-file")[0].click();
            window.showAddSuccess();
            this.uploaded = false;
          }
        })
        .catch(() => {
          debugger;
          window.showError();
        });
      debugger;
      var isSession = this.documentModel.IsFromSession;
      var PatientReservationSessionID =
        this.documentModel.PatientReservationSessionID;
      var DoctorID = this.documentModel.DoctorID;
      this.documentModel = {};

      this.documentModel.PatientReservationSessionID =
        PatientReservationSessionID;
      this.documentModel.DoctorID = DoctorID;
      this.documentModel.IsFromSession = isSession;
    },
    successUpload(event) {
      debugger;

      window.showAddSuccess();
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        debugger;
        let data = new FormData();
        data.append("file", input.target.files[0]);
        debugger;
        axios
          .post(this.uploadUrl, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            debugger;
            var temp = {};
            if (response.data != "File not Supoorted") {
              temp.AttachPath = response.data;
              debugger;
              if (this.documentModel.DocumentID == 1)
                this.documentModel.Name = "Scan Type";
              if (this.documentModel.DocumentID == 2)
                this.documentModel.Name = "Analysis Type";
              if (this.documentModel.DocumentID == 3)
                this.documentModel.Name = "Report Type";
              debugger;
              this.documentModel.Attach = input.target.files[0].name;
              this.documentModel.AttachPath = response.data;

              this.documentModel.PatientID =
                this.$store.state.AppActiveUser.Patient.ID;
              this.ShowPatientUploadDocument = true;
              this.documentModel.PatientReservationSessionID =
                this.updatedPatientSession.ID;
              debugger;
              this.documentModel.DoctorID = this.updatedPatientSession.DoctorID;
              this.documentModel.IsFromSession = true;
              this.ReportDocuments.push(this.documentModel);
              this.documentModel = {};
            } else {
              this.ShowPatientUploadDocument = false;
            }

            // this.AddAttachmnet();
          });
      }
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }
    this.$store.dispatch("ScanTypeList/GetAllScanTypes");

    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }
    this.$store.dispatch("ReportTypeList/GetAllReportTypes");

    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }
    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");

    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }
    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
if(this.$store.state.AppActiveUser.Patient&&this.$store.state.AppActiveUser.Patient.ID){
  this.$store
      .dispatch(
        "patientList/GetAllProfilePatientMedicalReports",
        this.$store.state.AppActiveUser.Patient.ID
      )
      .then((res) => {
        if (res.status == 200) {
          this.ProfileReportDocuments = res.data.Data;
        }
      })
      .catch(() => {
        window.showError();
      });
}
   
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
