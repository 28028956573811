/*=========================================================================================
  File Name: moduleLanguage.js
  Description: Language Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleDocumentTypeState.js'
import mutations from './moduleDocumentTypeMutations.js'
import actions from './moduleDocumentTypeActions.js'
import getters from './moduleDocumentTypeGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
