<template>

  <div class="checkout-items">
    <b-card :style="selectedDoctorID == doctor.ID ? { backgroundColor: '#D2FFFE' } : {}" v-for="doctor in searchDoctors"
      :key="doctor.id" class="ecommerce-card" no-body>

      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img class="round" width="100px" height="150px" v-if="doctor.ProfileImagePath"
            :src="baseURL + doctor.ProfileImagePath" :alt="`${doctor.Name}-${doctor.ID}`" />
          <b-img class="round" width="100px" height="150px" v-else alt="" src="@/assets/images/DoctorPlaceHolder.png" />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>

        <b-row>
          <b-col class="item-name " cols="12" md="6">
            <h6 class="mb-0">
              <b-link class="text-body" @click="$router.push('/DoctorProfile/' + doctor.ID)"
                v-if="doctor.CompleteFullName"> {{ $t("Dr") }}.{{ doctor.CompleteFullName }}
              </b-link>
              <b-link class="text-body" @click="$router.push('/DoctorProfile/' + doctor.ID)" v-else> {{ $t("Dr") }}.{{
                  doctor.Name
              }}
              </b-link>
            </h6>
            <span class="item-company"> {{ $t("Destination") }}: <b-link class="company-name">{{ doctor.CountryName
            }}</b-link></span>
            <br /><span class="item-company"> {{ $t("Speciality") }}: <b-link class="company-name">{{ doctor.Specialties
            }}</b-link></span>
            <br /> <span class="item-company"> {{ $t("Title") }}: <b-link class="company-name">{{ doctor.Title
            }}</b-link></span>


          </b-col>
          <b-col class="item-name item-options" cols="12" md="6">


            <div class="item-rating">
              <span class="item-company">{{ $t('Rate') }}: </span>
              <rate :rateValue="doctor.Rate" />
              <span class="item-company">{{ $t('RecommendationLevel') }}: </span>
              <rate :rateValue="doctor.RecommendedLevel" />



            </div>
          </b-col>
        </b-row>
        <!-- <span class="delivery-date text-muted">Delivery by {{ formatDate(doctor.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span> -->
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              ${{ doctor.PriceSessionInUSD }}
            </h4>

          </div>
        </div>
        <b-button variant="light" class="mt-1 remove-wishlist"
          @click="viewAppointment(doctor); selectedDoctorID = doctor.ID" v-if="doctor.HaseAppointemts != true">
          <!-- <feather-icon
            icon="XIcon"
            class="mr-50"
          /> -->
          <span>{{ $t("RequestAppointment") }}</span>
        </b-button>
        <b-button variant="primary" class="btn-cart move-cart"
          @click="viewAppointment(doctor); selectedDoctorID = doctor.ID" v-if="doctor.HaseAppointemts == true">
          <!-- <feather-icon
            icon="HeartIcon"
            class="mr-50"
            :class="{'fill-current': doctor.isInWishlist}"
          /> -->
          <span>{{ $t("OnlineAppointment") }}</span>
        </b-button>
      </div>
    </b-card>
  </div>

</template>

<script>
import { domain } from "@/gloabelConstant.js";
import { BBadge, BButton, BCard, BCardBody, BCol, BFormSpinbutton, BImg, BLink, BRow } from 'bootstrap-vue';
export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton, BRow, BCol
  },
  data() {
    return {
      selectedDoctorID: null,
      minDate: new Date(),
      isSelected: false,
      baseURL: domain,
      sessionModel: {},
      patientNote: "",
      showSetSession: false,
      showSetPayment: false,
      showBiography: false,
      showCancelPop: false,
      showPayLaterPop: false,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
      Session: {},
      ShowPatientUploadDocument: false,
      documentModel: {},
      updatedPatientSession: {},
      ShowLoginPopup: false,
      ShowPatientNote: false,
      monthSessions: [],
      paymentStatus: false,
      search: {
        IsBlocked: false,
      },
    };
  },
  methods: {
    viewAppointment(doctor) {
      // this.doctor = doctor
      this.$emit('viewAppointment', doctor)

    },
  },
  props: {
    searchDoctors: {
      type: Array,
      default: () => { },
    },
    // doctor: {
    //   type: Object,
    //   default: () => {},
    // },
  },
}
</script>

<style>

</style>
