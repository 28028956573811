var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"list-view product-checkout",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"flex-column align-items-start"},[_c('h4',[_vm._v(_vm._s(_vm.$t("OneSetAppointment")))]),_c('h5',{staticClass:"pl-10 pr-10",staticStyle:{"text-align":"center","opacity":"1"}},[_vm._v(" "+_vm._s(_vm.$t("ProccedOnlineAppointment"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("withDoctor") + " ")),_c('span',{staticStyle:{"color":"#2753D9"}},[_vm._v(_vm._s(_vm.sessionModel.DoctorName))])])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('imageLazy',{attrs:{"imageStyle":{
                'border-radius': '20px',
                width: '20vh',
                height: '20vh',
              },"imageClass":'doctorImage',"src":_vm.baseURL + _vm.sessionModel.doctorImg,"placeHolderType":"doctor"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',{staticClass:"text-start mt-1"},[_c('label',{attrs:{"for":"fname"}},[_vm._v(_vm._s(_vm.$t("Date"))+" :")]),_c('p',[_vm._v(" "+_vm._s(_vm.changeFormatedDate(_vm.sessionModel.SessionDate))+" ")])]),_c('b-row',[_c('label',[_vm._v(_vm._s(_vm.$t("Time"))+" :")]),(
                  _vm.sessionModel.TimeFrom != null && _vm.sessionModel.TimeTo != null
                )?_c('p',{staticClass:"text-start"},[_vm._v(" "+_vm._s(_vm.sessionModel.TimeFrom.slice(0, 5))+" - "+_vm._s(_vm.sessionModel.TimeTo.slice(0, 5))+" ")]):_vm._e()]),_c('b-row',[_c('label',[_vm._v(_vm._s(_vm.$t("Doctor"))+" :")]),_c('p',[_vm._v(_vm._s(_vm.sessionModel.DoctorName))])]),_c('b-row',[_c('label',[_vm._v(_vm._s(_vm.$t("Destination"))+" :")]),_c('p',[_vm._v(" "+_vm._s(_vm.sessionModel.Country)+" ")])]),_c('b-row',[_c('label',[_vm._v(_vm._s(_vm.$t("Speciality"))+" :")]),_c('p',[_vm._v(_vm._s(_vm.sessionModel.Specialties))])])],1)],1)],1)],1),_c('div',{staticClass:"customer-card"},[_c('b-card',{attrs:{"title":_vm.$t('AppointmentPatientConditionTitle')}},[_c('b-card-text',{staticClass:"mb-0"},[_c('p',{staticStyle:{"color":"orange"}}),_c('ul',{staticClass:"m-2"},[_c('li',{staticStyle:{"color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t("AppointmentPatientConditionFirst"))+" ")]),_c('li',{staticStyle:{"color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t("AppointmentPatientConditionSecond"))+" ")]),_c('li',{staticStyle:{"color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t("AppointmentPatientConditionThird"))+" ")])])]),_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.Proceed()}}},[_vm._v(" "+_vm._s(_vm.$t("Book"))+" ")]),_c('b-button',{attrs:{"block":""},on:{"click":function($event){return _vm.$emit('Previos-step')}}},[_vm._v(" "+_vm._s(_vm.$t("ChooseAnotherDoctor"))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }