<template>
  <div class=" ">
    <vx-card>
      <div slot="no-body">
        <div class="no-gutter justify-center items-center pb-3" style="
                  box-shadow: -3px 0px 22px #00000029;
                  border-radius:31px 31px 31px 31px;
               opacity: 1;
              ">
          <p style="text-align: center;
                    font: normal normal 200 20px/21px Futura PT;
                         letter-spacing: 0px;
                          color: black">

            <img src="@/assets/images/footer_logo.png" style="max-width:150px ;" alt="login" class="mt-1" />
            <br />
            <!-- in order to -->
            <span class=""> {{ $t('For') }} {{ $t(this.MessageAction) }}, {{ $t('PleaseLoginOrRegisterFirst') }}</span>
            <!-- please click here to sign in or register" -->
            <br />
            <b-button class="w-full sm:w-auto mt-2 text-center text-primary" color="white" @click="GotoLogin">{{
              $t("Here") }}</b-button>
          </p>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import {
  BButton
} from "bootstrap-vue";
export default {
  data() {
    return {

    };
  },
  components: {
    BButton
  },
  props: {
    MessageAction: {
      type: Object,
      required: true
    },

  },
  methods: {
    GotoLogin() {
      this.$router.push('/login');
    }
  },
  created() {

  },
};
</script>
<style>
#userNotLogin table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px#00000029;

}


#userNotLogin .background {
  color: rgb(255, 9, 9);
}
</style>
