<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <h4>{{ $t("OneSetAppointment") }}</h4>
          <h5
            class="pl-10 pr-10"
            style="
              text-align: center;

              opacity: 1;
            "
          >
            {{ $t("ProccedOnlineAppointment") }}
            <br />
            {{ $t("withDoctor") + " "
            }}<span style="color: #2753D9">{{ sessionModel.DoctorName }}</span>
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Full Name -->
            <b-col cols="12" md="6">
              <imageLazy
                :imageStyle="{
                  'border-radius': '20px',
                  width: '20vh',
                  height: '20vh',
                }"
                :imageClass="'doctorImage'"
                :src="baseURL + sessionModel.doctorImg"
                placeHolderType="doctor"
              />
            </b-col>
            <b-col cols="12" md="6">
              <b-row class="text-start mt-1">
                <label for="fname">{{ $t("Date") }} :</label>
                <p>
                  {{ changeFormatedDate(sessionModel.SessionDate) }}
                </p>
              </b-row>
              <b-row>
                <label>{{ $t("Time") }} :</label>
                <p
                  class="text-start"
                  v-if="
                    sessionModel.TimeFrom != null && sessionModel.TimeTo != null
                  "
                >
                  {{ sessionModel.TimeFrom.slice(0, 5) }} -
                  {{ sessionModel.TimeTo.slice(0, 5) }}
                </p>
              </b-row>
              <b-row>
                <label>{{ $t("Doctor") }} :</label>
                <p>{{ sessionModel.DoctorName }}</p>
              </b-row>
              <b-row>
                <label>{{ $t("Destination") }} :</label>
                <p>
                  {{ sessionModel.Country }}
                </p>
              </b-row>
              <b-row>
                <label>{{ $t("Speciality") }} :</label>
                <p>{{ sessionModel.Specialties }}</p>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <div class="customer-card">
        <b-card :title="$t('AppointmentPatientConditionTitle')">
          <b-card-text class="mb-0">
            <p style="color: orange"></p>
            <ul class="m-2">
              <li style="color: orange">
                {{ $t("AppointmentPatientConditionFirst") }}
              </li>
              <li style="color: orange">
                {{ $t("AppointmentPatientConditionSecond") }}
              </li>
              <li style="color: orange">
                {{ $t("AppointmentPatientConditionThird") }}
              </li>
            </ul>
          </b-card-text>
          <!-- Submit Button -->
          <b-button variant="primary" block @click="Proceed()">
            {{ $t("Book") }}
          </b-button>
          <b-button
            block
            @click="$emit('Previos-step')"
          >
          {{ $t("ChooseAnotherDoctor") }}
          </b-button>
        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import {
BButton,
BCard,
BCardBody,
BCardHeader,
BCardText,
BCardTitle,
BCol,
BForm,
BFormGroup,
BFormInput,
BFormInvalidFeedback,
BImg,
BRow
} from "bootstrap-vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    // BSV
    imageLazy,
    BImg,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    sessionModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      baseURL: domain,
      updatedPatientSession: {},
      // session :{}
    };
  },
  methods: {
    changeFormatedDate(date) {
      return moment(date).format("LL");
    },
    Proceed() {
      debugger;
      //this.$emit("next-step", this.sessionModel);
      var model = {};
      model.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      if (this.sessionModel.SessionID)
        model.DoctorSessionID = this.sessionModel.SessionID;
      else model.DoctorSessionID = this.sessionModel.ID;

      model.DoctorID = this.sessionModel.DoctorID;
      model.PaymentStatusID = 1; //pending
      model.StatusID = 1;
      model.MarkupFees = this.sessionModel.MarkupFees;
      model.VAT = this.sessionModel.VAT;
      this.$vs.loading();
      var vm = this;
      this.$store
        .dispatch("DoctorList/AddPatientReservationSession", model)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.$vs.loading.close();
            vm.updatedPatientSession = res.data.Data;
            this.showSetSession = false;
            //this.showSetPayment = true;
            window.showAddSuccess();

            this.isSelected = false;
            this.$emit("next-step",this.sessionModel, vm.updatedPatientSession);
          }
        })
        .catch((err) => {
          console.log(err);
          window.showError(err.response.data.Message);
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
