/*=========================================================================================
  File Name: moduleAnalysisTypeMutations.js
  Description: AnalysisType Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.AnalysisTypes.unshift(item)
  },
  SET_AnalysisType(state, AnalysisTypes) {
    state.AnalysisTypes = AnalysisTypes
  },
  UPDATE_AnalysisType(state, AnalysisType) {
    const AnalysisTypeIndex = state.AnalysisTypes.findIndex((p) => p.ID == AnalysisType.ID)
    Object.assign(state.AnalysisTypes[AnalysisTypeIndex], AnalysisType)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.AnalysisTypes.findIndex((p) => p.ID == itemId)
    state.AnalysisTypes.splice(ItemIndex, 1)
},
}
