<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <doctor-list @viewAppointment="viewAppointment" :searchDoctors="searchDoctors"  />

    <!-- Search card -->
    <div style="" class="">
      
      <b-col class="checkout-options sticky" >
      <!-- <b-col class="checkout-options" style="position: fixed;float:left"  md="3" ></b-col>-->

      <b-card >
        <!-- <b-card style="max-height:450px; overflow-y:scroll"> -->

        <!-- {{showAppointments}} ++ {{doctor}} -->
        <h6  v-if="showAppointments&&doctor" class="mb-0 item-name" style="color:#8379F2;font-weight: bold;" >
            <b-link v-if=" doctor.CompleteFullName"> <span class="company-name">{{ $t("Dr") }}.{{ doctor.CompleteFullName }}</span> 
            </b-link>
            <b-link  v-else>  <span class="company-name"> {{ $t("Dr") }}.{{ doctor.Name }} </span> 
            </b-link>
          </h6>
        <label class="section-label mb-1">Options</label>
        <v-date-picker
              @update:from-page="pageChange"
              :locale="$vs.rtl ? 'ar' : 'en'"
              :attributes="[ {
          bar: 'green',
          dates: this.monthSessions.map((ob) => ob.DateFrom),
        },]"
              :min-date="minDate"
              style="width: 100%"
              v-if="showAppointments&&doctor&&doctor.HaseAppointemts"
              v-model="appointmentDate"
              @input="getAppointmentSessions"
            />

            <h1 class="price-title" v-if="!showAppointments"> {{ $t("PleaseSelectOnADoctor") }} </h1>
        <hr v-if="showAppointments&&doctor&&doctor.HaseAppointemts">
        <div class="price-details">
          <h6 class="price-title" v-if="showAppointments&&doctor&&doctor.HaseAppointemts">
            {{ $t("SelectATimeFor") }} {{ appointmentDateStrig }} :
          </h6>
          <b-row v-if="showAppointments" >
              <b-row
                v-for="item in sessions"
                :key="item.name"
                class="p-0 m-0"
                
              >
                <div v-on:click="selectSession(item)">
                  <div
                    class="m-1"
                    :style="[
                      !item.IsAvailable
                        ? { 'background-color': 'gray' }
                        : item.ID == Session.ID
                        ? {
                            'background-color': '#004477',
                            'font-color': 'white',
                          }
                        : item.IsBlocked
                        ? { 'background-color': 'red', 'font-color': 'white' }
                        : {'background-color': 'green', 'font-color': 'white'},
                    ]"
                  >
                    <p
                      v-if="item.DateFrom != null && item.DateTo != null"
                      :style="[
                        item.ID == Session.ID
                          ? { color: 'white' }
                          : item.IsBlocked
                          ? { color: 'white' }
                          : !item.IsAvailable
                          ? { color: 'white' }
                          : {color: 'white'},
                      ]"
                      class="text-center m-1"
                    >
                      {{ item.DateFrom.slice(11, 16) }} -
                      {{ item.DateTo.slice(11, 16) }}
                        </p>
                      </div>
                </div>
              </b-row>
            </b-row>
          <hr>
          <p
              class="m-2 ml-5"
              v-if="
                (sessions.length > 0 &&
                  !(
                    sessions.findIndex(
                      (element) => element.IsAvailable == true
                    ) >= 0
                  )) ||
                (doctor.HaseAppointemts != true && showAppointments)
              "
              style="font-size: 16px"
            >
              {{ $t("onlineAppointmentSpecificDateMessage") }} 
              <u style="color: blue">
                <a
                  :href="
                    'mailto:patient.service@doclinia.com' +
                    '?subject=' +
                    'Request an online consultation with Dr.' +
                    doctor.Name +
                    ',Date:' +
                    changeFormatedDate(this.appointmentDate)
                  "
                  >patient.service@doclinia.com</a
                >
              </u>
            </p>

            <b-row  v-if="Session.ID > 0 && showAppointments">
              <b-col>
                <div
                  class="mt-8 mb-5 mr-8 flex flex-wrap items-center justify-end"
                >
                <b-button
                :disabled="!Session.ID"
            variant="primary"
            block
            @click="$emit('next-step',Session,doctor)"
          >
          {{ $t("Procced") }}
          </b-button>
        
                </div>
              </b-col>
            </b-row>
         
        </div>

      </b-card>
    </b-col>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import {
BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue';
import moment from "moment";
import DoctorList from './DoctorList.vue';

export default {
  components: {
    // BSV
    BLink,
    BButton,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    DoctorList,
  },
  props: {
    searchDoctors: {
      type: Array,
      default: () => {},
    },
    
  },
  data() {
    return {
      doctor:{},
      minDate: new Date(),
      isSelected: false,
      baseURL: domain,
      sessionModel: {},
      patientNote: "",
      showSetSession: false,
      showSetPayment: false,
      showBiography: false,
      showAppointments: false,
      showCancelPop: false,
      showPayLaterPop: false,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
      Session: {},
      ShowPatientUploadDocument: false,
      documentModel: {},
      updatedPatientSession: {},
      ShowLoginPopup: false,
      ShowPatientNote: false,
      monthSessions: [],
      paymentStatus: false,
      search: {
        IsBlocked: false,
      },
    };
  },
  methods: {
   
    pageChange(obj) {
      this.sessions = [];
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
      };
      search.To.setDate(search.To.getDate() + 1);
      search.DateTo = moment(search.To).format("LL");
      search.doctorID = this.doctor.ID;
      search.IsBlocked = false;
      this.$store
        .dispatch("DoctorList/SearchDoctorMonthSessions", search)
        .then((res) => {
          debugger;
          this.monthSessions = res.data.Data;
          if (this.monthSessions && this.monthSessions.length > 0) {
            this.doctor.HaseAppointemts = true;
            var index = Math.ceil(this.monthSessions.length / 2);
            var session = this.monthSessions[index];
            this.doctor.PriceSessionInUSD = session.TotalPriceInUSD;

          }
          else{
              this.doctor.HaseAppointemts = false;
            }
        });
    },

    changeFormatedDate(date) {
      return moment(date).format("LL");
    },

   
    
    selectSession(session) {
      this.doctor.PriceSessionInUSD = session.TotalPriceInUSD;
      debugger;
      this.Session = session;
    },
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    viewAppointment(doctor) {
      debugger;
      this.doctor = doctor
      this.sessions= [];
      this.Session= {};
      this.monthSessions = [];
      this.showAppointments = true;
      this.appointmentDate = new Date();
        this.getAppointmentSessions();
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },

    getAppointmentSessions() {
      debugger;
      this.sessions = [];
      this.Session = {};
      var dateNow = new Date();
      {
        debugger;
        this.appointmentDateStrig = moment(this.appointmentDate).format("LL");
        this.search.DoctorID = this.doctor.ID;
        this.search.DayUTC = this.appointmentDateStrig;
        this.sessions = [];
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/SearchDoctorSessions", this.search)
          .then((res) => {
            debugger;
            this.sessions = res.data.Data;
            this.$vs.loading.close();
            debugger;
            if (this.sessions == null || this.sessions.length == 0) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
}
</script>

<style lang="scss">

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 25%;
  // padding: 80px;
  font-size: 25px;
}
</style>