/*=========================================================================================
  File Name: moduleDocumentTypeActions.js
  Description: DocumentType Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddDocumentType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/DocumentType/AddDocumentType", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllDocumentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/DocumentType/GetAllDocumentTypes")
        .then((response) => {
          commit('SET_DocumentType', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateDocumentType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/DocumentType/UpdateDocumentType", item)
        .then((response) => {
          commit('UPDATE_DocumentType', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetDocumentType({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/DocumentType/GetDocumentType", itemid)
        .then((response) => {
          commit('UPDATE_DocumentType', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteDocumentType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/DocumentType/DeleteDocumentType?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
