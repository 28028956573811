<template>
  <div class="app-img">

    <div
      v-if="dataUrl||src.includes('null')"
      class="app-img__placeholder"
      :class="imageClass"
      style="border-radius: 20px;"
    >
   
      <img 
      :style="imageStyle"
      :class="imageClass"
      :src="placeholderImage" alt="" v-bind="$attrs" />
    </div>
    <img
      v-if="!src.includes('null')"
      :src="dataUrl"
      :alt="$attrs.alt || ''"
      class="app-img__img"
     
      :style="imageStyle"
    /> 
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: true,
    },
    placeholder: String,
    imageStyle:Object,
    imageClass:String,
    placeHolderType:String
  },
  data(){
     return {
          placeholderImage:"" 
     }
  },
  computed: {
    dataUrl() {
      const { width, height } = this.$attrs
      if (!width || !height) return ""

      // create a tiny png with matching aspect ratio as img
      const w = 100
      const canvas = document.createElement("canvas")
      canvas.width = w
      canvas.height = (height / width) * w

      return canvas.toDataURL()
    },
  },
  created()
  { 
      
    this.placeholderImage=this.placeholder;

    if(!this.placeholder&&this.placeHolderType)
     switch(this.placeHolderType)
     {

        case "patient":this.placeholderImage=`${require('@/assets/images/patient.jpg')}`; break;
        case "hospital":this.placeholderImage=`${require('@/assets/images/HospitalPlaceHolder.png')}`; break;
        case "doctor":this.placeholderImage=`${require('@/assets/images/DoctorPlaceHolder.png')}`; break;
        case "package":this.placeholderImage=`${require('@/assets/images/face.png')}`; break;
        case "country":this.placeholderImage=`${require('@/assets/images/country.jpg')}`; break;
     }
    
  },
  watch:
  {
     src:function()
     {
        const { src, $el } = this
        
        const img = $el.querySelector(`.app-img__img`);
        if(img)
            img.src = src
     }
  },

  mounted() {
    
    const { src, $el } = this
    let timeOut

    const observer = new IntersectionObserver(([entry]) => {
      const img = $el.querySelector(`.app-img__img`)
      const placeholder = $el.querySelector(`.app-img__placeholder`)
    if(img)
    {
    if(this.imageClass)
             img.classList.add(this.imageClass)
      img.onload = function() {
        delete img.onload
        $el.classList.add(`app-img--loaded`)
        
        if (placeholder) {
          timeOut = setTimeout(() => {
            placeholder.remove()
          }, 300)
        }
      }
    }
      if (entry.isIntersecting) {
        // Element is in viewport
         if(img)
         {
            img.src = src
         }
        observer.disconnect()
      }
    
    })
    observer.observe($el)

    this.$once("hook:beforeDestroy", () => {
      observer.disconnect()
      if (timeOut) {
        clearTimeout(timeOut)
      }
    })

 
  },
  beforeMount()
  {
    
  },
  beforeCreate()
  {
    
  }
}
</script>

<style>
.app-img {
  display: inline-block;
  position: relative;
}

.app-img__placeholder {
  overflow: hidden;
}

.app-img__placeholder img {
  transform: scale(1.05);
  /* filter: blur(10px); */
}

.app-img__img {
  opacity: 0;
  transition: opacity 300ms ease;
}

.app-img--loaded .app-img__img {
  opacity: 1;
}
</style>