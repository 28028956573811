/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    doctors: [],
    searchDoctors: [],

    patientReservation: [],
    monthPatientReservation: [],
    pendingTaskList: [],
    Langauges: [],
    specialities: [],
    search:{
      SpecialtyID: "",
        CountryID: "",
        CityID: "",
        DoctorID: "",
        IsPaging: true,
        PageNumber: 1,
        PageSize: 10,
        ForAppointment: true,
    },

}